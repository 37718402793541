body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: rgb(24, 24, 24);
  /* background-image: url("../public/images/poly_background.jpg"); */

  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.navigation {
  font-family: 'Roboto Mono';
}
.nav-link.active {
  color: #FFDB80 !important;
}


.btn-warning {
  background-color: hsl(43, 100%, 64%);
  border: 0;
  border-radius: 15px;
}
.btn-warning:hover {
  background-color: hsl(43, 69%, 54%);
}


a {
  color: #FFDB80;
}
a:hover {
  color: #c2a55c;
  text-decoration: none;
}


/* Home page */
.homeContacts div.card {
  opacity: 0.8;
  background-color: hsl(43, 100%, 64%);
  border: 0;
  border-radius: 10%;
}
.homeContacts div.card:hover {
  background-color: hsl(43, 58%, 44%);
}

.homeContacts a {
  color: rgb(24, 24, 24);
}
.homeContacts a svg {
  height: auto;
  width: 100%;
}

/* Projects card */
.card-demo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Contacts page */
.contactsDeck div.card {
  background-color: hsl(43, 100%, 64%);
  border: 0;
  border-radius: 15px;
}
.contactsDeck div.card:hover {
  background-color: hsl(43, 58%, 44%);
}

.contactsDeck a {
  color: rgb(24, 24, 24);
}
.contactsDeck a svg {
  height: auto;
  width: 100%;
}

@media (max-width: 575px) {
  .contactsDeck div.card {
    height: 80px !important;
    width: 80vw;
  }
  .contactsDeck a svg {
    height: 80px;
    width: auto;
  }
}

/* Badge colouring */
.badge.React {
  background-color: rgb(97, 218, 251);
  color: black;
}
.badge.Bootstrap {
  background-color: rgb(86, 61, 124);
  color: white;
}
.badge.NodeJS {
  background-color: rgb(144, 197, 63);
  color: black;
}
.badge.JavaScript {
  background-color: #f1e05a;
  color: black;
}

.badge.Java {
  background-color: #b07219;
  color: white;
}

.badge.Python {
  background-color: #3572A5;
  color: white;
}

.badge.C\+\+ {
  background-color: #3572A5;
  color: white;
}